import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getActionByType, getCodeFromType, getCodes } from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import { ModuleActionProps } from 'types'

const SquarePopup = ({
  align,
  flat,
  linkTo,
  showOn,
  image,
  utmValue,
  name,
  type,
  property,
  development,
  properties,
  developments,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')
  const { data: allPushData } = useGetPushQuery()

  const [isOpen, setIsOpen] = useState(false)

  // Timeout to show the component
  useEffect(() => {
    if (checkPage() && !isOpen) {
      if (
        (showOn === 'Ficha de emprendimiento' && development) ||
        (showOn === 'Ficha de la propiedad' && property) ||
        (showOn !== 'Ficha de emprendimiento' && showOn !== 'Ficha de la propiedad')
      ) {
        informCustomEvent('SHOW_' + getCodes(type, showOn) + '_' + name)
        setTimeout(() => {
          if ((flat && screen.width > 992) || !flat)
            document.getElementById('squarePopUpBtn')?.click()
          setIsOpen(true)
        }, 2000)
      }
    }
  }, [pathname, property, development])

  // Check align
  const checkAlign = () => {
    return align?.toLowerCase() === 'left'
      ? 'align-left'
      : align?.toLowerCase() === 'right'
      ? 'align-right'
      : align?.toLowerCase() === 'center'
      ? 'align-center'
      : ''
  }

  // Limit the height
  const checkFlat = () => {
    return flat ? 'flat-width d-none d-lg-flex' : ''
  }

  // Go to link, disable popup
  const goTo = () => {
    document.getElementById('closePopup')?.click()
    if (linkTo) {
      window.open(linkTo + utmValue, '_blank', 'noopener,noreferrer')
      informCustomEvent('PUSH_' + getCodes(type, showOn) + '_' + name)
    }
  }

  // Check where shows
  const checkPage = () => {
    // if(properties?.length > 0 && property?.id && properties?.find(prop => prop?.toString() === property?.id?.toString()) ||
    // developments?.length > 0 && development? && developments?.find(dev => dev?.toString() === development.id?.toString())){
    if (developments?.length! > 0 || properties?.length! > 0) {
      //Si es para una ficha en particular
      if (
        existSquarePopDetail(property?.toString()!) ||
        existSquarePopDetail(development?.toString()!)
      ) {
        if (
          properties?.find((prop) => prop?.toString() === property?.toString()) ||
          developments?.find((dev) => dev?.toString() === development?.toString())
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === '' && showOn.toLowerCase() === 'inicio') {
        return true
      }
      if (pathname === 'quienes-somos' && showOn.toLowerCase() === 'nosotros') {
        return true
      }
      if (pathname === 'servicios' && showOn.toLowerCase() === 'tasaciones') {
        return true
      }
      if (
        pathname.toLowerCase().includes('emprendimiento') &&
        pathname.length > 18 &&
        showOn.toLowerCase() === 'ficha de emprendimiento' &&
        !existSquarePopDetail(development?.toString()!)
      ) {
        return true
      }
      if (
        pathname.toLowerCase().includes('propiedad') &&
        showOn.toLowerCase() === 'ficha de la propiedad' &&
        !existSquarePopDetail(property?.toString()!)
      ) {
        return true
      }
      if (pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta') {
        return true
      }
      if (pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler') {
        return true
      }
      if (
        pathname.toLowerCase() === 'temporario' &&
        showOn.toLowerCase() === 'resultados alquiler temporario'
      ) {
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  const existSquarePopDetail = (id: string) => {
    const squarePopUps = [
      ...getActionByType(allPushData, getCodeFromType('PopUp Vertical')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Cuadrado')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Timer')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Panoramico')),
    ]

    if (id) {
      for (const singleSquare of squarePopUps) {
        for (const dev of singleSquare.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of singleSquare.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  return (
    <>
      {checkPage() ? (
        <div className="article">
          <button
            type="button"
            id="squarePopUpBtn"
            data-bs-toggle="modal"
            data-bs-target="#squarePopUp"
          ></button>
          <div
            className="modal fade"
            id="squarePopUp"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className={`modal-dialog ${checkAlign()} ${checkFlat()}`}>
              <div
                className={`modal-content ${checkFlat()}`}
                style={{ backgroundImage: 'url(' + image + ')' }}
              >
                <div
                  className="modal-header"
                  onClick={() => document.getElementById('closePopup')?.click()}
                >
                  <i
                    className="icon-plus"
                    role="button"
                    id="closePopup"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => document.getElementById('closePopup')?.click()}
                  ></i>
                </div>
                <div
                  className={`modal-body ${checkFlat()}`}
                  onClick={() => goTo()}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SquarePopup

// Component usage
// <SquarePopup -> Square aligned center by default
//      align={'right' || 'left' || 'center'} -> Set the align for vertical banner
//      flat={true} -> Square flat with max-height
//      linkTo={'/emprendimientos/'} -> Set the link of image
//      showOn={'servicios' | ['venta','alquiler'] } /> -> Set route where shows the banner

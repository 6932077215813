import React from 'react'
import { RealEstateDataHeaderData, RealEstateDataPage } from 'types'
import Boilerplate_Header_1 from '@boilerplates/header/1'

interface ExternalProps {
  pages: RealEstateDataPage[]
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header2 = (props: CombinedProps) => {
  return <Boilerplate_Header_1 {...props}/>
}

export default Header2

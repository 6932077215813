import './index.scss'
import { Link } from 'gatsby'
import { Division } from 'types'
import { useLocation } from '@reach/router'
import React, { ReactElement } from 'react'
import { Dropdown } from 'react-bootstrap'

interface ExternalProps {
  divisions: Division[]
  divisionPath: string
}

const Supra_NavBar_1 = ({ divisions, divisionPath }: ExternalProps) => {
  // Location
  const currentPage = useLocation()
    ?.pathname?.split('/')
    ?.filter((p) => p.length > 0)[0]

  // Links
  const divisionsLinks = (dropdownItemStyle: boolean = false): ReactElement[] => {
    return divisions?.map(({ path, title }, index) => (
      <Dropdown.Item active={path === divisionPath}>
        <Link
          key={index}
          to={path}
          className={`text-uppercase ${path === divisionPath ? 'active ' : ''} ${
            dropdownItemStyle ? 'dropdown-item p-2' : ''
          } `}
        >
          {title}
        </Link>
      </Dropdown.Item>
    ))
  }

  return (
    divisions && (
      <section className="supra-navbar">
        {/* Desktop Nav */}
        <div className="d-none d-lg-flex justify-content-end text-right w-100 supra-nav-d">
          {divisions?.map(({ path, title }, index) => (
            <Link
              key={index}
              to={path}
              className={`text-uppercase ${path === divisionPath ? 'active ' : ''}`}
            >
              {title}
            </Link>
          ))}
        </div>
        {/* Mobile Nav */}
        <div className="d-flex d-lg-none justify-content-end text-right w-100 supra-nav-m">
          <Dropdown>
            <Dropdown.Toggle
              className="text-uppercase btn dropdown-toggle px-lg-3 d-flex align-items-center justify-content-center justify-content-lg-around btn nav-btn"
              id="dropdown-basic"
            >
              {currentPage}
              <i className="ms-3 ms-lg-0 icon-arrow-light"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>{divisionsLinks(true)}</Dropdown.Menu>
          </Dropdown>
        </div>
      </section>
    )
  )
}

export default Supra_NavBar_1

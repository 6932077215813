import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { graphql, useStaticQuery } from 'gatsby'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getActionByType, getCodeFromType, getCodes } from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import { ModuleActionProps } from 'types'
const CounterPopup = ({
  linkTo,
  linkTxt,
  title,
  text,
  showOn,
  band,
  eventDate,
  utmValue,
  type,
  name,
  properties,
  property,
  developments,
  development,
}: ModuleActionProps) => {
  const Square = require('./cuadro.svg') as string

  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')

  const pop: any = useRef()
  const { data: allPushData } = useGetPushQuery()
  const [isOpen, setIsOpen] = useState(false)

  // Timeout to show the component
  useEffect(() => {
    if (checkPage() && !isOpen) {
      if (
        (showOn === 'Ficha de emprendimiento' && development) ||
        (showOn === 'Ficha de la propiedad' && property) ||
        (showOn !== 'Ficha de emprendimiento' && showOn !== 'Ficha de la propiedad')
      ) {
        informCustomEvent('SHOW_' + getCodes(type, showOn) + '_' + name)
        setTimeout(() => {
          pop?.current?.open()
          setIsOpen(true)
        }, 2000)
      }
    }
  }, [pathname, property, development])

  // Check where shows
  const checkPage = () => {
    // if(properties.length > 0 && property?.id && properties.find(prop => prop?.toString() === property.id?.toString()) ||
    // developments.length > 0 && development && developments.find(dev => dev?.toString() === development.id?.toString())){
    if (developments?.length! > 0 || properties?.length! > 0) {
      //Si es para una ficha en particular
      if (existTimerPopDetail(property?.toString()) || existTimerPopDetail(development)) {
        if (
          properties?.find((prop) => prop?.toString() === property?.toString()) ||
          developments?.find((dev) => dev?.toString() === development?.toString())
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === '' && showOn.toLowerCase() === 'inicio') {
        return true
      }
      if (pathname === 'servicios' && showOn.toLowerCase() === 'tasaciones') {
        return true
      }
      if (
        pathname.toLowerCase().includes('emprendimiento') &&
        pathname.length > 18 &&
        showOn.toLowerCase() === 'ficha de emprendimiento' &&
        !existTimerPopDetail(development)
      ) {
        return true
      }
      if (
        pathname.toLowerCase().includes('propiedad') &&
        showOn.toLowerCase() === 'ficha de la propiedad' &&
        !existTimerPopDetail(property?.toString())
      ) {
        return true
      }
      if (pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta') {
        return true
      }
      if (pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler') {
        return true
      }
      if (
        pathname.toLowerCase() === 'temporario' &&
        showOn.toLowerCase() === 'resultados alquiler temporario'
      ) {
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  const existTimerPopDetail = (id: string | undefined) => {
    const TimerPop = [
      ...getActionByType(allPushData, getCodeFromType('PopUp Vertical')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Cuadrado')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Timer')),
      ...getActionByType(allPushData, getCodeFromType('PopUp Panoramico')),
    ]
    if (id) {
      for (const singleSquare of TimerPop) {
        for (const dev of singleSquare.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of singleSquare.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  // Render countdown
  const rendererCtd = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: string
    hours: string
    minutes: string
    seconds: string
  }) => (
    <div className="counter d-flex justify-content-center text-center mt-4">
      <div className="mx-2 d-flex flex-column justify-content-center">
        <span className="date-val">{days}</span>
        <span className="date-text">DÍAS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{hours}</span>
        <span className="date-text">HS</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{minutes}</span>
        <span className="date-text">MIN</span>
      </div>
      <div className="me-2 d-flex flex-column justify-content-center">
        <span className="date-val">{seconds}</span>
        <span className="date-text">SEG</span>
      </div>
    </div>
  )

  return checkPage() ? (
    <Popup
      ref={pop}
      position="right center"
    >
      <i
        className="icon-plus close"
        role="button"
        id="closePopup"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => pop.current.close()}
      ></i>
      {/* <button className="close" > */}
      {/* &times; */}
      {/* </button> */}
      <div className={`content-border ${band ? 'show-band' : 'hide-band'}`}>
        {/* <Square/> */}
        {band && (
          <div className="band">
            <span className="text-band text-center">{band}</span>
          </div>
        )}
        {/* <Star className="star-icon"/> */}
        <div className="star-icon">
          <i className="icon-favoritos-star-solid"></i>
        </div>
        <h1 className="text-center text-uppercase">{title}</h1>
        {text && <p className="popup-text my-3 text-center">{text}</p>}
        {eventDate && (
          <Countdown
            date={(new Date(eventDate!) as any).getTime()}
            renderer={rendererCtd}
          />
        )}
        {linkTo && (
          <a
            className="d-flex text-center align-items-center justify-content-center text-uppercase"
            onClick={() =>
              informCustomEvent('PUSH_' + getCodes(type, showOn) + '_' + name) + pop.current.close()
            }
            href={linkTo + utmValue}
            target="_blank"
          >
            {linkTxt}
          </a>
        )}
      </div>
    </Popup>
  ) : null
}

export default CounterPopup

// Component usage
// <CounterPopup
//      linkTo={'/servicios'} -> url link
//      linkTxt={'A un click de conocerlo'} -> text link
//      title={'¡Nuevo emprendimiento!'} -> text title
//      showOn={'nosotros'} -> where show | add to components page
//      band={'Proyecto de pozo, texto de prueba'} -> band top right
//      eventDate={'9/15/2022'}/> -> event date

import { orderNovelties } from '@helpers/helper.novelty'
import { mediacoreApi } from '../mediacoreApi'
import { ResponseSummaryBlog } from 'types'
const blogExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query<ResponseSummaryBlog, { site: string }>({
      query: ({ site = '' }) => ({
        url: `blog/novedades/get_data_summary/?client=${process.env.GATSBY_CLIENTID}&site=${site}`,
      }),
      transformResponse: (data: ResponseSummaryBlog, _headers, extraOptions) => {
        const { site } = extraOptions as { site: string }
        return { news: orderNovelties(data.news, site), tags: data.tags }
      },
    }),
    getDetailBlog: builder.query({
      query: ({ BLOG_ID }) =>
        `blog/novedades/${BLOG_ID}/get_data_detail/?client=${process.env.GATSBY_CLIENTID}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetBlogsQuery, useGetDetailBlogQuery } = blogExtended

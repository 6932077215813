import { adapt_property_data, adapt_summary_data } from '@helpers/helper.mediacore'
import { mediacoreApi } from '../mediacoreApi'
import { PropertyMediacore } from 'types'

const propertiesExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ CLIENT_ID, params_get}) => ({
        url: `site/propiedades/template/?client=${CLIENT_ID}`,
        method: "POST",
        body:params_get
      }),
      transformResponse: (data:PropertyMediacore[]) => ({
        meta: { total_count: data.length },
        objects: data.map(prop => adapt_property_data(prop)),
      }),
    }),
    getProperty: builder.query({
      query: ({ CLIENT_ID, PROP_ID }) => `site/propiedades/${PROP_ID}/ficha/?client=${CLIENT_ID}`,

      transformResponse: (data) => adapt_property_data(data),
    }),
    getSummary: builder.query({
      // query: ({ CLIENT_ID,OPERATION }) => `site/propiedades/summary/?client=${CLIENT_ID}&operation_type=${OPERATION === 'temporario' ? 'alquiler temporario':OPERATION}`,
      query: ({ CLIENT_ID, OPERATION, TYPE }) =>
        `site/propiedades/summary/?client=${CLIENT_ID}${
          OPERATION
            ? '&operation_type=' + (OPERATION === 'temporario' ? 'alquiler-temporario' : OPERATION)
            : ''
        }${TYPE ? '&property_type=' + TYPE?.map((element:{name:string,id:string}) => element.name) : ''}`,

      // transformResponse: data => ({objects: {...data,operation_types: [{ count: 53, operation_type: 1 }],}, }),
      transformResponse: (data) => ({ objects: adapt_summary_data(data) }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetPropertiesQuery, useGetPropertyQuery, useGetSummaryQuery } = propertiesExtended

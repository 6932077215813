import { Link } from 'gatsby'
import React, { useState, Fragment, useEffect } from 'react'
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap'
import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { RealEstateDataHeaderData, RealEstateDataPage, StatePropertiesSlice } from 'types'
import useQuerySummary from '@hooks/useQuerySummary'
import toast, { Toaster } from 'react-hot-toast'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { makeItemsNav } from '@helpers/helper.rendering'
import { getfavorites } from '@helpers/helper.favorites'
import { useSelector } from 'react-redux'
import { useLocation } from '@reach/router'
import $ from 'jquery'

const isBrowser = typeof window !== 'undefined'
interface ExternalProps {
  pages: RealEstateDataPage[]
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header1 = (props: CombinedProps) => {
  const { isDemo, pages, global_data } = props
  const { divisionPath, id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko

  const location = useLocation()

  const { titles, isInverted, buttonCompare, buttonHeart } = isDemo ? props : props

  const [open, setOpen] = useState<boolean>(false)

  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })
  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const { updaterFavorites } = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )!

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )
  const hasModules = (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module)?.length > 0

  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const logo = global_data.logo
  const logoMobile = global_data.logoMobile

  const icons: string = global_data.pageConfig.design.icons
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''
  const countFavorites = listFavorites.length

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    scrollTo(0, 0)
  }, [location])

  if (isMounted) {
    $(document).ready(function () {
      const header = document.querySelector('#header') as HTMLElement
      let content = document.querySelector('body') as HTMLElement
      content.style.paddingTop = header?.clientHeight + 'px'
      scrollTo(0, 0)
    })
  }

  useEffect(() => {
    if (isBrowser) {
      setListFavorites(getfavorites('prop', 'template-site'))
    }
  }, [updaterFavorites])

  return isMounted ? (
    <header
      id="header"
      className={`a header position-fixed blur ${open ? 'opened' : ''}${
        isInverted ? ' inverted ' : ''
      }`}
    >
      <Navbar
        sticky="top"
        className=" brand"
      >
        <Container
          fluid
          className="mx-auto justify-content-center"
        >
          <Row className="justify-content-center align-items-center w-100">
            <Col
              xs={3}
              className={'d-flex d-lg-none align-items-center justify-content-lg-center'}
            ></Col>
            <Col
              lg={9}
              xs={6}
              className="justify-content-lg-start justify-content-center"
            >
              <Link
                aria-label="Enlace inicio"
                className="d-lg-block d-flex align-items-center justify-content-center m-0"
                to="/"
              >
                <img
                  alt="logo-header"
                  src={logoMobile}
                  height={'75'}
                  width={'100'}
                  className="d-lg-none d-block align-top m-auto logo-header"
                />
                <img
                  alt="logo-header"
                  src={logo}
                  height={'50'}
                  width={'150'}
                  className="d-lg-inline-block d-none align-top me-2 logo-header"
                />
              </Link>
            </Col>
            <Col
              xs={3}
              className="text-right d-flex align-items-center justify-content-end"
            >
              {/* <span
                id=""
                className={'ms-2 d-none d-lg-block ' + ' animate__animated '}
              >
                <i className={`icon-favoritos-heart`}></i>
              </span>
              {buttonCompare?.value && (
                <div className={'me-4 d-none d-lg-block'}>
                  <div
                    style={{ color: '#c4c4c4' }}
                    className="compare-link ms-4"
                  >
                    {buttonCompare?.value}
                  </div>
                </div>
              )} */}
              <Toaster></Toaster>
              {countFavorites === 0 ? (
                <span
                  className="hover-like d-lg-flex d-none align-items-center"
                  onClick={() => toast.error('No tienes Propiedades favoritas seleccionadas.')}
                >
                  {buttonCompare?.value}
                  <i
                    className={
                      `me-4 ms-2 icon-favoritos-heart${iconsStyle} ` +
                      (countFavorites < 1 && ' disabled ')
                    }
                  ></i>
                </span>
              ) : (
                <div className={'hover-like cursor-pointer d-lg-flex d-none align-items-center '}>
                  <span
                    id="count_favorites"
                    className={'ms-lg-3 d-flex align-items-center ' + ' animate__animated '}
                  >
                    {countFavorites === 1 ? (
                      <span
                        onClick={() =>
                          toast.error(
                            'Debes tener mas de una propiedad en favoritos para comparar.',
                          )
                        }
                        className={'hover-like disabled d-lg-flex d-none align-items-center '}
                      >
                        {buttonCompare?.value}
                      </span>
                    ) : (
                      <Link
                        to={buttonCompare?.link!}
                        className={'hover-like d-lg-flex d-none align-items-center '}
                      >
                        {buttonCompare?.value}
                      </Link>
                    )}
                    <div className="ms-4"></div>
                    {countFavorites}
                    <Link to={buttonHeart?.link!}>
                      <i className={`me-4 ms-2 icon-favoritos-heart${iconsStyle} `}></i>
                    </Link>
                  </span>
                </div>
              )}
              <button
                id="toggle-nav"
                aria-label="toggle"
                onClick={() => setOpen(!open)}
              >
                <div className="d-flex align-items-center">
                  <span className="status_nav d-none d-lg-inline">MENÚ </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </Col>
          </Row>
          <div className="nav-content opened d-flex align-items-center">
            <Container fluid>
              <Row>
                <Col
                  lg={8}
                  className="pe-xl-5"
                  style={{ alignSelf: 'center' }}
                >
                  <Nav className="nav-menu d-flex flex-column d-lg-block">
                    {makeItemsNav(allSummaryData, allBlogData, pages)
                      .filter((page) => !page.path.includes('detalle'))
                      .map(({ path, title }, index) => (
                        <Fragment key={index}>
                          <Nav.Item>
                            <Link
                              onClick={() => setOpen(false)}
                              className={'text-uppercase '}
                              to={(divisionPath ?? '') + path}
                            >
                              {title}
                              <span>{index + 1}</span>
                            </Link>
                          </Nav.Item>
                        </Fragment>
                      ))}
                  </Nav>
                  <div
                    className={
                      'item-contact row mb-0 button-separator text-center private-access-container ' +
                      (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                        ? 'd-block d-lg-none'
                        : ' d-none')
                    }
                  >
                    <h5 className="private-access">ACCESOS PRIVADOS</h5>
                    {INVESTOR_CONFIGURATION?.module && (
                      <div className="d-block">
                        <a
                          className="btn btn-investor mb-2 d-block d-lg-inline-block"
                          target={'_blank'}
                          href={INVESTOR_CONFIGURATION.url_login}
                        >
                          {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                        </a>
                      </div>
                    )}
                    {OWNER_CONFIGURATION?.module && (
                      <div className="d-block">
                        <a
                          className="btn btn-investor mb-2 d-block d-lg-inline-block"
                          target={'_blank'}
                          href={OWNER_CONFIGURATION.url_login}
                        >
                          {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                        </a>
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  lg={4}
                  className="px-xl-5 contact-nav d-none d-lg-flex align-items-center"
                >
                  <Row>
                    <Col
                      xs={8}
                      lg={12}
                      className="item-contact"
                    >
                      <h5>{titles?.global}</h5>
                      {contact_data?.email! && (
                        <a
                          className="hover-service-link "
                          target="_blank"
                          href={'mailto:' + contact_data?.email}
                        >
                          {contact_data.email}
                        </a>
                      )}
                      {contact_data.phoneLine?.map((phone, index) => (
                        <p
                          key={index}
                          className={'mb-0 mb-lg-0 ' + (phone === '' ? 'd-none' : '')}
                        >
                          Teléfono {phone}
                        </p>
                      ))}
                    </Col>
                    <Col
                      xs={12}
                      className="max-heigth-scroll"
                    >
                      {branch_office.length > 0 &&
                        branch_office?.map((item, index) => (
                          <Col
                            xs={12}
                            key={index}
                            className="item-contact mb-lg-5 mt-lg-4 d-none d-lg-block"
                          >
                            <h5>
                              {titles?.branch_office} {item.name}
                            </h5>
                            <p>{item.address + ', ' + item.city}</p>
                            <p>{item.province}, Argentina</p>
                            {item.contact_data.emails.map((mail, index) => (
                              <a
                                key={index}
                                target="_blank"
                                className={'hover-link ' + (!mail && 'd-none')}
                                href={'mailto:' + mail}
                              >
                                {mail}
                              </a>
                            ))}
                            {item.contact_data.phonesLine.map((phone, index) => (
                              <p key={index}>Teléfono {phone}</p>
                            ))}
                            {item.contact_data.phonesMobile.map((whatsapp, index) => (
                              <a
                                key={index}
                                className={'hover-footer-link'}
                                target="_blank"
                                href={
                                  'https://api.whatsapp.com/send?' +
                                  'phone=54' +
                                  whatsapp.replaceAll('-', '')
                                }
                              >
                                Whatsapp + {whatsapp}
                              </a>
                            ))}
                            <p className={item.registration_number === '' ? 'd-none' : ''}>
                              Matrícula {item.college + ' ' + item.registration_number}
                            </p>
                          </Col>
                        ))}
                    </Col>
                    <Col
                      lg={12}
                      xs={4}
                      className="item-contact social"
                    >
                      <h5>{titles?.social}</h5>
                      {social?.instagram && (
                        <a
                          target="_blank"
                          aria-label="Lea mas en la red social instagram"
                          href={social?.instagram}
                          className={social?.instagram ? 'd-inline' : 'd-none'}
                        >
                          <i className={`icon-instagram${iconsStyle}`}></i>
                        </a>
                      )}
                      {social?.facebook && (
                        <a
                          target="_blank"
                          aria-label="Lea mas en la red social facebook"
                          href={social?.facebook}
                          className={social?.facebook ? 'd-inline' : 'd-none'}
                        >
                          <i className={`icon-facebook${iconsStyle}`}></i>
                        </a>
                      )}
                      {social?.linkedin && (
                        <a
                          target="_blank"
                          aria-label="Lea mas en la red social linkedin"
                          href={social?.linkedin}
                          className={social?.linkedin ? 'd-inline' : 'd-none'}
                        >
                          <i className={`icon-linkedin${iconsStyle}`}></i>
                        </a>
                      )}
                      {social?.youtube && (
                        <a
                          target="_blank"
                          aria-label="Lea mas en la red social youtube"
                          href={social?.youtube}
                          className={social?.youtube ? 'd-inline' : 'd-none'}
                        >
                          <i className={`icon-youtube${iconsStyle}`}></i>
                        </a>
                      )}
                      {social?.twitter && (
                        <a
                          target="_blank"
                          aria-label="Lea mas en la red social twitter"
                          href={social?.twitter}
                          className={social?.twitter ? 'd-inline' : 'd-none'}
                        >
                          <i className={`icon-twitter${iconsStyle}`}></i>
                        </a>
                      )}
                    </Col>
                    {hasModules ? (
                      <Col
                        className={
                          'item-contact row mb-0 button-separator ' +
                          (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                            ? 'd-block'
                            : ' d-none')
                        }
                      >
                        <h5 className="">ACCESOS PRIVADOS</h5>
                        {INVESTOR_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn text-white btn-investor mb-2 d-block d-lg-inline-block"
                              target={'_blank'}
                              href={INVESTOR_CONFIGURATION.url_login}
                            >
                              {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                            </a>
                          </div>
                        )}
                        {OWNER_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn text-white btn-investor mb-2 d-block d-lg-inline-block"
                              target={'_blank'}
                              href={OWNER_CONFIGURATION.url_login}
                            >
                              {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                            </a>
                          </div>
                        )}
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </Navbar>
    </header>
  ) : (
    ''
  )
}

export default Header1

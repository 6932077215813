import * as React from 'react'
import { connect } from 'react-redux'
import AlertTop from '@components/Modules/MediaPush/AlertTop'
import CounterPopup from '@components/Modules/MediaPush/CounterPopup'
import FlapButton from '@components/Modules/MediaPush/FlapButton'
import SquarePop from '@components/Modules/MediaPush/SquarePop'
import {
  getActionByType,
  getCodeFromType,
  getSectionFromCode,
  getTypeFromCode,
} from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import {
  Action,
  Development,
  Property,
  RealEstateData,
  RealEstateDataPage,
  RealEstateDataPageComponentData,
} from 'types'
import { useLocation } from '@reach/router'
import { useGetPropertyQuery } from '@apis/mediacore/properties'
import { useGetDevelopmentQuery } from '@apis/mediacore/emprendimientos'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'

const isBrowser = typeof window !== 'undefined'

const Modules = ({
  development_id,
  property_id,
  page,
  global_data,
}: {
  development_id?: string
  property_id?: string
  page?: RealEstateDataPage
  global_data: RealEstateData
}) => {
  const API_KEY = global_data.pageConfig.keys.tokko

  const { data: allPushData, isLoading: loading } = useGetPushQuery()
  const { data: property } = useQueryProperty(property_id, API_KEY)
  const { data: development } = useQueryDevelopment(development_id, API_KEY)

  const { pathname } = useLocation()

  const getId = () => {
    switch (page?.path) {
      case '/desarrollo_detalle':
        return pathname.split('/').at(-2)
      case '/venta':
        return pathname.split('/').at(-2)
      default:
        null
    }
  }

  return (
    !loading &&
    isBrowser && (
      <>
        {getActionByType(allPushData, getCodeFromType('Solapa Flotante')).map(
          (action: Action, index: number) => (
            <FlapButton
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              utmValue={action.texts.text_utm}
              linkTxt={action.texts.text_limited}
              alignment={action.alignment}
              type={getTypeFromCode(action.type)}
              showOn={getSectionFromCode(action.section)}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Timer')).map(
          (action: Action, index: number) => (
            <CounterPopup
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              title={action.title}
              showOn={getSectionFromCode(action.section)}
              band={action.texts.text_band}
              text={action.texts.text}
              utmValue={action.texts.text_utm}
              type={getTypeFromCode(action.type)}
              eventDate={action.date_start}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Vertical')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={action.alignment}
              flat={false}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Cuadrado')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={''}
              flat={false}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('PopUp Panoramico')).map(
          (action: Action, index: number) => (
            <SquarePop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              type={getTypeFromCode(action.type)}
              align={''}
              flat={true}
              linkTo={action.url_destiny}
              image={action.file}
              utmValue={action.text_utm}
              showOn={getSectionFromCode(action.section)}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('Barra Encabezado Timer')).map(
          (action: Action, index: number) => (
            <AlertTop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              textAlert={action.texts?.text}
              showOn={getSectionFromCode(action.section)}
              type={getTypeFromCode(action.type)}
              utmValue={action.texts.text_utm}
              eventDate={action.date_start}
            />
          ),
        )}
        {getActionByType(allPushData, getCodeFromType('Barra Encabezado')).map(
          (action: Action, index: number) => (
            <AlertTop
              property={property}
              key={index}
              properties={action.properties}
              development={development}
              developments={action.developments}
              name={action.name}
              linkTo={action.texts.text_URL}
              linkTxt={action.texts.text_button}
              textAlert={action.texts?.text}
              type={getTypeFromCode(action.type)}
              utmValue={action.texts.text_utm}
              eventDate={action.date_start}
              showOn={getSectionFromCode(action.section)}
            />
          ),
        )}
      </>
    )
  )
}

export default connect((state) => ({}), null)(Modules)

import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown'
import { getActionByType, getCodeFromType, getCodes } from '@helpers/helper.actions'
import { informCustomEvent } from '@helpers/helper.analytics'
import { useGetPushQuery } from '@apis/mediacore/push'
import { ModuleActionProps } from 'types'

const TopAlert = ({
  linkTo,
  linkTxt,
  textAlert,
  showOn,
  eventDate,
  utmValue,
  type,
  name,
  properties,
  property,
  developments,
  development,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replace('/', '')

  const [closed, setClosed] = useState(false)

  useEffect(() => {
    if (checkPage()) {
      if (
        (showOn === 'Ficha de emprendimiento' && development) ||
        (showOn === 'Ficha de propiedad' && property) ||
        (showOn !== 'Ficha de emprendimiento' && showOn !== 'Ficha de la propiedad')
      ) {
        if (closed === false) {
          informCustomEvent('SHOW_' + getCodes(type, showOn) + '_' + name)
          setTimeout(() => {
            document.getElementById('top-alert-' + name)?.classList.add('animate-show')
            document.getElementById('gatsby-focus-wrapper')?.classList.toggle('top-custom-body')
          }, 500)
        }
      }
    } else {
      document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
      document.getElementById('gatsby-focus-wrapper')?.classList.remove('top-custom-body')
    }
  }, [pathname, showOn, property, development])

  useEffect(() => {
    if (closed) {
      document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
      document.getElementById('gatsby-focus-wrapper')?.classList.remove('top-custom-body')
    }
  }, [closed])

  // Check where shows
  const { data: allPushData, isLoading: loading } = useGetPushQuery()

  // Render countdown
  const rendererCtd = ({ days, hours, minutes, seconds }: any) => (
    <div className="counter d-flex">
      <span className="mx-2">
        <span className="date-val">{days}</span> DÍAS
      </span>
      <span className="me-2">
        <span className="date-val">{hours}</span> HS
      </span>
      <span className="me-2">
        <span className="date-val">{minutes}</span> MIN
      </span>
      <span className="me-2">
        <span className="date-val">{seconds}</span> SEG
      </span>
    </div>
  )

  const capitalizeFirst = (text: string) => text[0].toUpperCase() + text.slice(1, text.length)

  const checkPage = () => {
    if (developments?.length! > 0 || properties?.length! > 0) {
      //Si es para una ficha en particular
      if (pathname.length < 18) {
        return false
      }
      if (existAlertTopDetail(property?.id!) || existAlertTopDetail(development?.id!)) {
        if (
          properties?.find((prop) => prop?.toString() === property?.id?.toString()) ||
          developments?.find((dev) => dev?.toString() === development?.id?.toString())
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === '' && showOn.toLowerCase() === 'inicio') {
        return true
      }
      if (pathname === 'servicios' && showOn.toLowerCase() === 'tasaciones') {
        return true
      }
      if (
        pathname.toLowerCase().includes('emprendimientos') &&
        pathname.length > 18 &&
        showOn.toLowerCase() === 'ficha de emprendimiento' &&
        !existAlertTopDetail(development?.id!)
      ) {
        return true
      }
      if (
        pathname.toLowerCase().includes('propiedad') &&
        showOn.toLowerCase() === 'ficha de la propiedad' &&
        !existAlertTopDetail(property?.id!)
      ) {
        return true
      }
      if (pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta') {
        return true
      }
      if (pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler') {
        return true
      }
      if (
        pathname.toLowerCase() === 'temporario' &&
        showOn.toLowerCase() === 'resultados alquiler temporario'
      ) {
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  const existAlertTopDetail = (id: number) => {
    const squarePopUps = [
      ...getActionByType(allPushData, getCodeFromType('Barra Encabezado Timer')),
      ...getActionByType(allPushData, getCodeFromType('Barra Encabezado')),
    ]
    if (id) {
      for (const squarePopUp of squarePopUps) {
        for (const dev of squarePopUp.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of squarePopUp.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  return checkPage() ? (
    <div
      id={'top-alert-' + name}
      className={
        'alert alert-styles alert-dismissible fade show ' +
        (showOn.toLowerCase() === 'inicio' ? 'isHome' : '')
      }
      role="alert"
    >
      <div className="container-fluid">
        {/* Counter */}
        {eventDate && (
          <div className="counter-wrapper">
            Quedan 
            <Countdown
              date={new Date(eventDate).getTime()}
              renderer={rendererCtd}
            />
          </div>
        )}
        {/* Text */}
        {textAlert && (
          <div className={`text-content my-2`}>
            {eventDate ? ` ${textAlert}` : capitalizeFirst(textAlert)}
          </div>
        )}
        {/* Link */}
        {linkTo && linkTxt && (
          <a
            href={linkTo + utmValue}
            onClick={() => informCustomEvent('PUSH_' + getCodes(type, showOn) + '_' + name)}
            className="btn-link"
            target="_blank"
          >
            {linkTxt}
          </a>
        )}
      </div>
      {/* X close button */}
      <i
        className="icon-plus"
        id="closeAlert"
        data-bs-dismiss="alert"
        onClick={() => {
          //   document.getElementById('gatsby-focus-wrapper')?.classList.remove('top-custom-body')
          setClosed(true)
        }}
        aria-label="Close"
      ></i>
    </div>
  ) : (
    <div></div>
  )
}

export default TopAlert

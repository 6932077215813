import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getActionByType, getCodeFromType, getCodes } from '@helpers/helper.actions'
import { useGetPushQuery } from '@apis/mediacore/push'
import { ModuleActionProps } from 'types'

const FlapBtn = ({
  alignment = 'left',
  linkTo,
  linkTxt,
  showOn,
  utmValue,
  type,
  name,
  properties,
  property,
  developments,
  development,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')
  const { data: allPushData } = useGetPushQuery()

  const checkPage = () => {
    if (developments?.length! > 0 || properties?.length! > 0) {
      //Si es para una ficha en particular
      if (
        existFlapButtonDetail(property?.toString()!) ||
        existFlapButtonDetail(development?.id?.toString()!)
      ) {
        if (
          properties?.find((prop) => prop?.toString() === property?.toString()) ||
          developments?.find((dev) => dev?.toString() === development?.toString())
        ) {
          return true
        }
      }
      return false
    } else {
      if (pathname === '' && showOn.toLowerCase() === 'inicio') {
        return true
      }
      if (pathname === 'servicios' && showOn.toLowerCase() === 'tasaciones') {
        return true
      }
      if (
        pathname.toLowerCase().includes('emprendimiento') &&
        pathname.length > 18 &&
        showOn.toLowerCase() === 'ficha de emprendimiento' &&
        !existFlapButtonDetail(development?.id?.toString()!)
      ) {
        return true
      }
      if (
        pathname.toLowerCase().includes('propiedad') &&
        showOn.toLowerCase() === 'ficha de la propiedad' &&
        !existFlapButtonDetail(property?.toString()!)
      ) {
        return true
      }
      if (pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta') {
        return true
      }
      if (pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler') {
        return true
      }
      if (
        pathname.toLowerCase() === 'temporario' &&
        showOn.toLowerCase() === 'resultados alquiler temporario'
      ) {
        return true
      }
      return Array.isArray(showOn)
        ? showOn.toLowerCase().includes(pathname.toLowerCase())
        : pathname.toLowerCase() === showOn.toLowerCase() || false
    }
  }

  useEffect(() => {
    informCustomEvent('SHOW_' + getCodes(type, showOn) + '_' + name)
  }, [])

  const existFlapButtonDetail = (id: string) => {
    const flapButtons = [...getActionByType(allPushData, getCodeFromType('Solapa Flotante'))]
    if (id) {
      for (const flapButton of flapButtons) {
        for (const dev of flapButton.developments) {
          if (dev.toString() === id.toString()) {
            return true
          }
        }
        for (const prop of flapButton.properties) {
          if (prop.toString() === id.toString()) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }

  return (
    checkPage() && (
      <div
        id="flap-btn"
        className={`fixed-btn-wrapper ${alignment}`}
      >
        <a
          href={linkTo + utmValue}
          onClick={() => informCustomEvent('PUSH_' + getCodes(type, showOn) + '_' + name)}
          className="btn-link"
          target="_blank"
        >
          {linkTxt}
        </a>
      </div>
    )
  )
}

export default FlapBtn

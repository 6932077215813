import { mediacoreApi } from "../mediacoreApi"
import {Action} from 'types'
const pushExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getPush: builder.query<Action[],void>({
      query: () =>
        `push/acciones/${process.env.GATSBY_CLIENTID}/activas/`
    }),
  }),
  overrideExisting: false,
})

export const { useGetPushQuery } = pushExtended

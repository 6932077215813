import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { Container, Nav } from 'react-bootstrap'
import { RealEstateDataHeaderData, StatePropertiesSlice } from 'types'
import { useLocation } from '@reach/router'
import './index.scss'
import { useSelector } from 'react-redux'
import { getfavorites } from '@helpers/helper.favorites'
import toast, { Toaster } from 'react-hot-toast'

const Boilerplate_Header_1 = (props: RealEstateDataHeaderData) => {
  const {
    pages,
    global_data,
    hiddenLogo,
    leftSide,
    buttonCompare,
    divisions,
    isInverted,
    background_secondary,
    is_short,
  } = props
  const { divisionPath, id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const logo = global_data.logo
  const logoMobile = global_data.logoMobile
  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })
  //const icons: string = global_data.pageConfig.design.icons

  //const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const { updaterFavorites } = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )!
  const countFavorites = listFavorites.length

  const [open, setOpen] = useState(false)

  const staticBody = () => {
    var body = document.getElementsByTagName('html')[0]
    body.classList.toggle('overflow-hidden')
  }

  useEffect(() => {
    setListFavorites(getfavorites('prop', 'template-site'))
  }, [updaterFavorites])

  const SupraNavBar = require(`@components/supraNavBar/1`)?.default

  return (
    <header
      id="header"
      className={`blur b header-2 ${is_short ? 'small-height' : ''} ${open ? 'opened' : 'closed'} ${
        hiddenLogo && ' hidden-logo '
      } ${background_secondary && 'background-secondary'}${isInverted ? ' inverted ' : ''}${
        process.env.GATSBY_IS_SITE_COMPILER === 'true' ? ' is_multisite' : ' '
      }`}
    >
      <Toaster
        toastOptions={{
          className: 'toast-className',
          style: {
            maxWidth: 'fit-content',
          },
        }}
      />
      <Nav className="brand">
        {process.env.GATSBY_IS_SITE_COMPILER === 'true' && (
          <div className="divisions-nav d-flex align-items-center">
            <Container
              className="d-flex align-items-center justify-lg-content-between"
              fluid
            >
              <Link
                className="px-0"
                style={{ opacity: 1 }}
                to="/"
              >
                <img
                  src={logo}
                  alt=""
                />
              </Link>
              <SupraNavBar
                divisions={divisions}
                divisionPath={divisionPath}
              />
            </Container>
          </div>
        )}
        <div className="container-fluid">
          <div
            className={`row ${
              leftSide ? 'justify-content-lg-start' : 'justify-content-lg-between'
            } align-items-center ${
              process.env.GATSBY_IS_SITE_COMPILER === 'true'
                ? 'justify-content-end'
                : 'justify-content-between'
            }`}
          >
            <div
              className={`col-7 col-lg-1 d-flex justify-content-start justify-content-lg-start ${
                leftSide ? 'order-2 order-lg-2 ' : 'order-1 order-lg-1'
              }`}
            >
              <Link
                to={'/'}
                className="logo d-none d-lg-flex align-items-center"
              >
                <img
                  className="logo-header"
                  src={logo}
                ></img>
              </Link>
              <Link
                to={'/'}
                className="logo d-flex align-items-center d-lg-none"
              >
                <img
                  className="logo-header"
                  src={logoMobile}
                ></img>
              </Link>
            </div>
            <div
              className={`col-3 col-lg-10  d-flex ${
                leftSide
                  ? 'order-2 order-lg-1 justify-content-lg-start justify-content-end'
                  : 'order-2 order-lg-2 justify-content-end text-right'
              }`}
            >
              <ul
                className={`nav-menu ${
                  leftSide ? 'justify-content-start' : 'justify-content-lg-end'
                }`}
              >
                {makeItemsNav(allSummaryData, allBlogData, pages)
                  .filter((page) => !page.path.includes('detalle'))
                  .map(({ path, title }, index) => (
                    <Nav.Item key={index}>
                      <Link
                        onClick={() => {
                          setOpen(false),
                            document
                              .getElementsByTagName('html')[0]
                              .classList.remove('overflow-hidden')
                        }}
                        className={
                          'text-uppercase ' +
                          pathname +
                          ': ' +
                          +' , ' +
                          path +
                          ' ' +
                          ((pathname.includes(path) && pathname !== '' && path !== '/') ||
                          (path === '/' && pathname === '/') ||
                          (pathname.toLowerCase() === divisionPath?.toLowerCase() + '/' &&
                            path === '/')
                            ? 'active'
                            : '')
                        }
                        to={(divisionPath ?? '') + path}
                      >
                        {title}
                      </Link>
                    </Nav.Item>
                  ))}
              </ul>
              <button
                className="d-block d-lg-none"
                id="toggle-nav"
                onClick={() => {
                  setOpen(!open)
                  staticBody()
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="status_nav d-none d-lg-inline">Menú </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </div>

            {buttonCompare?.value && (
              <div className="col-9 col-lg-1 order-1 order-lg-3 d-lg-flex align-items-center justify-content-lg-end justify-content-center">
                {allSummaryData?.objects?.operation_types?.length > 0 &&
                  (countFavorites === 0 ? (
                    <div
                      className="btn btn-white btn-compare disabled-compare border d-flex align-items-center justify-content-center"
                      onClick={() => toast.error('No tienes Propiedades favoritas seleccionadas.')}
                    >
                      {screen.width > 992 ? buttonCompare?.value : ''}
                      <div
                        className="mx-3"
                        style={{ fontStyle: 'normal' }}
                      >
                        {countFavorites}
                      </div>
                      <i
                        className={`icon-favoritos-heart` + (countFavorites < 1 && ' disabled ')}
                      ></i>
                    </div>
                  ) : (
                    <Link to={buttonCompare.link}>
                      <div className="btn btn-white btn-compare border d-flex align-items-center justify-content-center">
                        {screen.width > 992 ? buttonCompare?.value : ''}
                        <div
                          className="mx-3"
                          style={{ fontStyle: 'normal' }}
                        >
                          {countFavorites}
                        </div>
                        <i className={`icon-favoritos-heart`}></i>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Nav>
    </header>
  )
}

export default Boilerplate_Header_1

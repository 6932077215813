import React from 'react'
import { store } from '@redux/store'
import { Provider } from 'react-redux'
import Modules from '@components/Modules'

export const wrapRootElement = ({ element }: any) => {
  return <Provider store={store}>{element}</Provider>
}

export const wrapPageElement = ({ element, props }: any) => {
  const {
    pageContext: { global_data, hidden_header, hidden_footer, divisions },
    development_id,
    property_id,
    novelty_id,
  } = props

  const {
    content: { header, pages, footer },
  } = global_data

  let Header
  try {
    Header = require(`./components/header/${header.type_id}`).default
  } catch (e) {
    return <></>
  }

  let Footer
  try {
    Footer = require(`./components/footer/${footer.type_id}`).default
  } catch (e) {
    return <></>
  }

  return (
    <>
      {!hidden_header && (
        <Header {...{ type_id: header.type_id, pages, global_data, divisions, ...header.data }} />
      )}
      <Modules
        development_id={development_id}
        property_id={property_id}
        global_data={global_data}
      />
      {element}
      {!hidden_footer && (
        <Footer
          {...{
            type_id: footer.type_id,
            data: footer.data,
            ...footer.data,
            pages,
            global_data,
            development_id,
            property_id,
            novelty_id,
          }}
        />
      )}
    </>
  )
}
